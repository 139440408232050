<template>
  <div id="data-list-list-view" class="data-list-container">
    <div class="flex flex-wrap-reverse items-center">
          <div class="p-3 mb-4 mr-4">
            <!-- ADD NEW -->
            <div
              class="p-3 rounded-lg cursor-pointer flex items-center justify-between text-lg font-medium text-base text-primary border border-solid border-primary"
              @click="updateOrders()"
            >
              <feather-icon icon="RefreshCcwIcon" svgClasses="h-4 w-4" />
              <span class="ml-2 text-base text-primary">Atualizar</span>
            </div>
          </div>
        </div>

    <div v-for="(items, date) in orders" v-bind:key="date">
      <h1 class="text-center">Pedidos ({{date}})</h1>
      <vs-table selected="" :data="items" >
        <template slot="thead">
          <vs-th sort-key="order_id">Pedido</vs-th>
          <vs-th sort-key="name">Colaborador</vs-th>
          <vs-th sort-key="plate">Prato</vs-th>
          <vs-th sort-key="shift">Turno</vs-th>
          <vs-th sort-key="shift">Observação</vs-th>
        </template>

        <template slot-scope="{data}">
          <tbody>
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <vs-td>
                <p class="order-name font-medium truncate">{{ tr.order_id }}</p>
              </vs-td>

              <vs-td>
                <p class="order-name font-medium truncate">{{ tr.name }}</p>
              </vs-td>

              <vs-td>
                <p class="order-plate">{{ tr.plate_name }}</p>
              </vs-td>

              <vs-td>
                <p class="order-shift">{{ getShift(tr.shift) }}</p>
              </vs-td>

              <vs-td>
                <p class="order-note">{{ tr.note }}</p>
              </vs-td>

            </vs-tr>
          </tbody>
        </template>

      </vs-table>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import ggcsApi from "@/services/ggcs";

export default {
  data() {
    return {
      orders: [],
      itemsPerPage: 10,
      currentPage: 0,
    }
  },
  created() {
    this.updateOrders()
    // this.orders = [
    //   {
    //     "order_id": 2,
    //     "shift": 1,
    //     "plate": "integral",
    //     "note": "note",
    //     "name": "MOISES FARIAS DOS SANTOS",
    //     "user_id": 4,
    //     "ambev_id": "99087644",
    //     "agenda_id": "2021-03-14T17:11:27.000Z",
    //     "date": "2021-07-01",
    //   },
    //   {
    //     "order_id": 2,
    //     "shift": 1,
    //     "plate": "integral",
    //     "note": "note",
    //     "name": "MOISES FARIAS DOS SANTOS",
    //     "user_id": 4,
    //     "ambev_id": "99087644",
    //     "agenda_id": "2021-03-14T17:11:27.000Z",
    //     "date": "2021-07-01",
    //   },
    // ]
  },
  computed: {
    currentDate() {
      return moment().format('DD/MM/YYYY');
    },
  },
  methods: {
    updateOrders(loadAndNotify = true) {

      if (loadAndNotify) {
        this.$vs.loading()
      }

      const self = this;
      return new Promise((resolve) => {
        ggcsApi.get("/buffets/orders/today")
        .then(res => {
          if (res.data.success) {
            //this.orders = res.data.result;
            this.orders = res.data.result.reduce((group, product) => {
              const { date } = product;
              group[date] = group[date] || [];
              group[date].push(product);
              return group;
            }, {});

          } else {
            if (loadAndNotify) {
              self.notifyError("Erro ao atualizar pedidos do dia.")
            }
          }
          resolve();
        })
        .catch(( error) => {
          console.log(error)
          if (loadAndNotify) {
            self.notifyError("Erro ao atualizar pedidos do dia.");
          }
          //reject();
        })
        .finally(() => {
          if (loadAndNotify) {
            this.$vs.loading.close();
          }
        })
      })
    },
    previousStatus(order) {
      this.changeStatus(order.buffet_order_id, order.status - 1)
    },
    nextStatus(order) {
      this.changeStatus(order.buffet_order_id, order.status + 1)
    },
    deliver(order) {
      this.changeStatus(order.buffet_order_id, 3)
    },
    changeStatus(id, status) {
      this.$vs.loading()

      ggcsApi.patch(`/buffets/orders/${id}`, {
        status,
      }).then(res => {
        // console.log("PATCH", `/buffets/orders/${id}`, res)
        if (res.data.success) {
          this.updateOrders(false).then(() => {
            this.notifySuccess(this, "Pedido atualizado.")
          }).catch(() => this.notifyError("Erro ao alterar status do pedido."))
        } else {
          this.notifyError("Erro ao alterar status do pedido.")
        }
      }).catch(() => {
        // console.error(error)
        this.notifyError("Erro ao alterar status do pedido.")
      }).finally(() => this.$vs.loading.close())
    },
    getShift(shift) {
      switch (shift) {
        case 0:
          return "11:00 - 12:00 (Turno B)";
        case 1:
          return "12:00 - 13:00 (Turno B)";
        case 2:
          return "13:00 - 14:00 (Turno B)";
        case 3:
          return "14:00 - 15:00 (Turno B)";
        case 4:
          return "18:00 - 19:00 (Turno C)";
        case 5:
          return "19:00 - 20:00 (Turno C)";
        case 6:
          return "20:00 - 21:00 (Turno C)";
        case 7:
          return "21:00 - 22:00 (Turno C)";
        case 8:
          return "01:00 - 02:00 (Turno A)";
        case 9:
          return "02:00 - 03:00 (Turno A)";
        case 10:
          return "03:00 - 04:00 (Turno A)";
        case 11:
          return "04:00 - 05:00 (Turno A)";
        default:
          return "Sem turno";
      }
    },
    getStatus(status) {
      switch (status) {
        case 0:
          return "Aguardando Preparo";
        case 1:
          return "Em Preparo";
        case 2:
          return "Pronto para Entrega";
        case 3:
          return "Entregue";
        default:
          return "Aguardando Preparo";
      }
    },
    notifyError(error) {
      const text = error;
      this.$vs.notify({
        title: "Erro",
        text: text,
        color: "danger",
        iconPack: "feather",
        icon: "icon-check",
        position: "top-right",
      });
    },
  }
}
</script>

<style lang="scss">
#data-list-list-view {
  @apply mt-8;
  .vs-con-table {

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search{
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          &+i {
            left: 1rem;
          }

          &:focus+i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 .8rem;
      padding: 0 1rem;

      tr{
          &.selected {
            cursor: default;
          }

          box-shadow: 0 4px 20px 0 rgba(0,0,0,.05);
          td{
            padding: 20px;
            &:first-child{
              border-top-left-radius: .5rem;
              border-bottom-left-radius: .5rem;
            }
            &:last-child{
              border-top-right-radius: .5rem;
              border-bottom-right-radius: .5rem;
            }
          }
          td.td-check{
            padding: 20px !important;
          }

          &:nth-child(2n) {
            background-color: #dfdfdf;
          }
      }
    }

    .vs-table--thead{
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text{
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check{
        padding: 0 15px !important;
      }
      tr{
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
